<template>
  <div class="block">
    <el-form :model="form" label-position="left" label-width="130px">
      <el-form-item label="INVOICE DATE">
        <el-date-picker
          v-model="form.invoiceDate"
          type="daterange"
          range-separator="to"
          start-placeholder="Start"
          end-placeholder="End"
          style="width: 40%"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="INVOICE NO">
        <el-col :span="6"
          ><el-input
            v-model="form.invoiceNo"
            placeholder="Please Input Invoice No"
          ></el-input
        ></el-col>
      </el-form-item>

      <el-form-item label="CUSTOMER NAME" prop="customerName">
        <el-select
          v-model="form.customerId"
          placeholder="Select Item"
          style="width: 40%"
        >
          <el-option
            v-for="item in customers"
            :key="item.id"
            :label="item.customerName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="CREATE BY">
        <el-col :span="10"
          ><el-input
            v-model="form.createBy"
            placeholder="Please Input Email Address"
          ></el-input
        ></el-col>
      </el-form-item>

      <el-form-item label="LASTUPDATE BY">
        <el-col :span="10"
          ><el-input
            v-model="form.lastUpdateBy"
            placeholder="Please Input Email Address"
          ></el-input
        ></el-col>
      </el-form-item>

      <el-form-item>
        <el-col :span="3"
          ><el-button type="primary" size="medium" plain round @click="search"
            >Search</el-button
          ></el-col
        >
        <el-col :span="1"
          ><el-button type="warning" size="medium" plain round @click="_export"
            >Export</el-button
          ></el-col
        >
      </el-form-item>
    </el-form>

    <el-row>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 1400px"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          width="280"
          label="PROJECT NAME"
          prop="projectName"
        ></el-table-column>
        <el-table-column
          width="120"
          label="INVOICE DATE"
          prop="invoiceDate"
        ></el-table-column>
        <el-table-column
          width="120"
          label="INVOICE NO"
          prop="invoiceNo"
        ></el-table-column>
        <el-table-column
          width="120"
          label="CUSTOMER"
          prop="customer.alias"
        ></el-table-column>
        <el-table-column
          width="130"
          label="CREATE"
          prop="createBy"
          :formatter="formatter"
        ></el-table-column>
        <el-table-column
          width="130"
          label="LASTUPDATE"
          prop="lastUpdateBy"
          :formatter="formatter2"
        ></el-table-column>

        <el-table-column fixed="right" width="260">
          <template slot-scope="scope">
            <el-button
              @click="modify(scope.row)"
              type="success"
              size="medium"
              plain
              round
              >Modify</el-button
            >
            <el-button
              @click="handleClick(scope.row)"
              type="danger"
              size="medium"
              plain
              round
              >Download</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
import { axiosInstance } from "@/api";
const XLSX = require("xlsx");
export default {
  data() {
    return {
      form: {
        invoiceDate: "",
        invoiceNo: "",
        customerId: "",
        createBy: "",
        lastUpdateBy: "",
      },

      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value1: "",
      value2: "",
      multipleSelection: [],
      customers: [],
      tableData: [],
      dialogFormVisible: false,
    };
  },

  created() {
    this.loadCustomer();
  },

  methods: {
    formatter(row, column) {
      const str = row.createBy;
      const words = str.split("@");
      return words[0];
    },

    formatter2(row, column) {
      const str = row.lastUpdateBy;
      const words = str.split("@");
      return words[0];
    },

    modify(row) {
      this.$router.push({
        name: "modifyInvoice",
        params: { invoiceId: row.id },
      });
    },

    _export(evt) {
      const data = [
        /* header */ [
          "客戶",
          "Invoice No.",
          "Invoice Date",
          "Project Name(全)",
          "合約金額",
          "付款期別",
          "申報幣別",
          "申報金額",
          "Invoice幣別",
          "Invoice 金額",
          "預計匯入日期",
          "實際匯入日期",
          "實際匯入幣別",
          "實際匯入金額",
          "匯入時的匯率",
          "匯入時的TWD",
          "匯兌損益",
        ],
      ];
      for (const row of this.multipleSelection) {
        data.push([
          row.customer.alias,
          row.invoiceNo,
          row.invoiceDate,
          row.projectName,
          "",
          "",
          "",
          "",
          row.currency,
          row.total,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ]);
      }
      /* convert state to workbook */
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
      /* generate file and send to client */
      XLSX.writeFile(wb, "sheetjs.xlsx");
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    loadCustomer() {
      axiosInstance.get("/customer").then((response) => {
        this.customers = response.data;
      });
    },

    search() {
      let params = {
        customerId: this.form.customerId,
        invoiceNo: this.form.invoiceNo,
        createBy: this.form.createBy,
        lastUpdateBy: this.form.lastUpdateBy,
      };
      if (this.form.invoiceDate) {
        params.startDate = this.form.invoiceDate[0];
        params.endDate = this.form.invoiceDate[1];
      }
      axiosInstance
        .get("/InvoiceContent?expand=customer&expand=payItem", { params })
        .then((response) => {
          this.tableData = response.data;
        });
    } /** 以上的payItem,customer資訊是分別從payItem,customer取得,(expand=customer&expand=payItem) 
      不從原本的invoice contaent取得的原因,是因為使用者要求當payItem,customer有修改時, 在invoice content可同時修改*/,

    handleClick(row) {
      axiosInstance
        .get(`/InvoiceContent/exportPdf/${row.id}`, { responseType: "blob" })
        .then((response) => {
          const downloadUrl = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );

          const link = document.createElement("a");

          link.href = downloadUrl;

          link.setAttribute("download", `${row.invoiceNo}.pdf`); //any other extension

          document.body.appendChild(link);

          link.click();

          link.remove();
        })
        .catch(console.error);
    },
  },
};
</script>

<style>
</style>